import { useState } from 'react';
import { CardContent } from '../components/CardContent';
import { LabTerminal } from '../components/LabTerminal';

export const LabPage = () => {
  const [output, setOutput] = useState<string>('');

  const handleTerminalData = (data: string) => {
    setOutput(prev => prev + data);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Lab Environment</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <CardContent className="p-6">
          <LabTerminal onData={handleTerminalData} />
        </CardContent>
        <CardContent className="p-6">
          <pre className="whitespace-pre-wrap">{output}</pre>
        </CardContent>
      </div>
    </div>
  );
};
