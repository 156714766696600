import { useState, useEffect } from 'react';
import { Terminal, Shield, Database, MonitorUp } from 'lucide-react';
import { LabTerminal } from '../LabTerminal';
import type { KaliLabProps } from '../../types';

export const KaliLab = ({ onSessionEnd }: KaliLabProps) => {
  const [timeRemaining, setTimeRemaining] = useState(3600); // 1 hour in seconds
  const [output, setOutput] = useState<string>('');

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(prev => {
        if (prev <= 0) {
          clearInterval(timer);
          if (onSessionEnd) onSessionEnd();
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [onSessionEnd]);

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleTerminalData = (data: string) => {
    setOutput(prev => prev + data);
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      {/* Header */}
      <header className="bg-gray-800 border-b border-gray-700">
        <div className="container mx-auto px-4 py-4">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-4">
              <Terminal className="w-6 h-6 text-green-400" />
              <h1 className="text-2xl font-bold">Kali Lab Environment</h1>
            </div>
            <div className="text-gray-300">
              Time remaining: {formatTime(timeRemaining)}
            </div>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <div className="container mx-auto px-4 py-6">
        <div className="grid grid-cols-12 gap-6">
          {/* Terminal Section */}
          <div className="col-span-8">
            <div className="bg-black rounded-lg overflow-hidden shadow-lg">
              <div className="bg-gray-800 px-4 py-2 flex items-center justify-between">
                <div className="flex space-x-2">
                  <div className="w-3 h-3 rounded-full bg-red-500"></div>
                  <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
                  <div className="w-3 h-3 rounded-full bg-green-500"></div>
                </div>
                <div className="text-sm text-gray-400">root@kali:~#</div>
              </div>
              <LabTerminal onData={handleTerminalData} className="h-[600px]" />
            </div>
          </div>

          {/* Sidebar */}
          <div className="col-span-4 space-y-6">
            {/* System Info */}
            <div className="bg-gray-800 rounded-lg p-4">
              <h2 className="text-xl font-semibold mb-4 flex items-center">
                <MonitorUp className="w-5 h-5 mr-2 text-blue-400" />
                System Info
              </h2>
              <div className="space-y-2 text-sm text-gray-300">
                <div>OS: Kali Linux 2024.1</div>
                <div>Kernel: 6.5.0-kali3-amd64</div>
                <div>Memory: 4GB RAM</div>
                <div>Storage: 20GB SSD</div>
              </div>
            </div>

            {/* Available Tools */}
            <div className="bg-gray-800 rounded-lg p-4">
              <h2 className="text-xl font-semibold mb-4 flex items-center">
                <Shield className="w-5 h-5 mr-2 text-green-400" />
                Available Tools
              </h2>
              <ul className="space-y-2 text-sm text-gray-300">
                <li>• Nmap</li>
                <li>• Metasploit</li>
                <li>• Wireshark</li>
                <li>• Burp Suite</li>
                <li>• John the Ripper</li>
              </ul>
            </div>

            {/* Lab Tasks */}
            <div className="bg-gray-800 rounded-lg p-4">
              <h2 className="text-xl font-semibold mb-4 flex items-center">
                <Database className="w-5 h-5 mr-2 text-purple-400" />
                Lab Tasks
              </h2>
              <ul className="space-y-2 text-sm text-gray-300">
                <li>1. System Reconnaissance</li>
                <li>2. Network Enumeration</li>
                <li>3. Vulnerability Scanning</li>
                <li>4. Exploitation Basics</li>
              </ul>
            </div>
          </div>
        </div>

        {/* Output/Logs Section */}
        <div className="mt-6">
          <div className="bg-gray-800 rounded-lg p-4">
            <h2 className="text-xl font-semibold mb-4">Output Log</h2>
            <pre className="bg-black rounded p-4 text-sm text-gray-300 h-48 overflow-auto">
              {output || 'No output yet...'}
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KaliLab;
