import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import DarkModeToggle from './components/DarkModeToggle';
import HomePage from './pages/HomePage';
import KaliLab from './components/KaliLab';
import { LabPage } from './pages/LabPage';

function App() {
  const [darkMode, setDarkMode] = useState(true);

  return (
    <div className={darkMode ? 'dark' : ''}>
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 text-gray-900 dark:text-white">
        <DarkModeToggle darkMode={darkMode} setDarkMode={setDarkMode} />
        <Router>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/labs/kali" element={<KaliLab />} />
            <Route path="/labs/:labId" element={<LabPage />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
