import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  AlertTriangle, 
  Terminal,
  Shield,
  Monitor,
  Laptop 
} from 'lucide-react';

const HomePage = () => {
  const navigate = useNavigate();
  const [activeNewsIndex, setActiveNewsIndex] = useState(0);

  const newsItems = [
    {
      id: 1,
      title: "New Kali Lab Environment Released!",
      content: "Practice real-world penetration testing in our secure environment.",
      date: "2024-11-06",
      type: "update"
    },
    {
      id: 2,
      title: "Major Vulnerability Discovered in IoT Devices",
      content: "Critical flaw affecting millions of smart home devices. Patch available now.",
      date: "2024-11-06",
      type: "alert"
    },
    {
      id: 3,
      title: "Upcoming Security Workshops",
      content: "Join our advanced exploitation and penetration testing workshops.",
      date: "2024-11-06",
      type: "event"
    }
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveNewsIndex(prev => (prev + 1) % newsItems.length);
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  const featuredLabs = [
    {
      id: "kali",
      name: "Kali Linux Lab",
      description: "Master the fundamentals of penetration testing with Kali Linux",
      icon: Terminal,
      difficulty: "Beginner",
      tags: ["Linux", "Terminal", "Basic Tools"]
    },
    {
      id: "network",
      name: "Network Security Lab",
      description: "Practice advanced network penetration testing techniques",
      icon: Monitor,
      difficulty: "Intermediate",
      tags: ["Wireshark", "Nmap", "Network Analysis"]
    },
    {
      id: "web",
      name: "Web Security Lab",
      description: "Test and exploit web application vulnerabilities",
      icon: Shield,
      difficulty: "Advanced",
      tags: ["XSS", "SQLi", "CSRF"]
    },
    {
      id: "exploit",
      name: "Exploit Development",
      description: "Learn advanced exploitation and vulnerability research",
      icon: Laptop,
      difficulty: "Expert",
      tags: ["Buffer Overflow", "ROP", "Shellcode"]
    }
  ];

  const handleLabClick = (labId: string) => {
    navigate(`/labs/${labId}`);
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      {/* Hero Section */}
      <section className="py-20 bg-gradient-to-br from-blue-600 to-purple-600 text-white">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="text-5xl font-bold mb-6">
              Master Ethical Hacking & Security
            </h1>
            <p className="text-xl mb-8">
              Practice real-world scenarios in our secure lab environments. 
              Start with our new Kali Linux lab.
            </p>
            <div className="flex justify-center space-x-4">
              <button 
                onClick={() => navigate('/labs/kali')}
                className="bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold hover:bg-gray-100"
              >
                Try Kali Lab
              </button>
              <button 
                onClick={() => navigate('/labs')}
                className="border-2 border-white text-white px-8 py-3 rounded-lg font-semibold hover:bg-white hover:text-blue-600"
              >
                View All Labs
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* News Ticker */}
      <div className="bg-gray-100 dark:bg-gray-800 py-4">
        <div className="container mx-auto px-4">
          <div className="flex items-center space-x-4">
            <AlertTriangle className="text-yellow-500" />
            <div className="overflow-hidden">
              <div className="transition-opacity duration-500">
                {newsItems[activeNewsIndex].title} - {newsItems[activeNewsIndex].content}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Featured Labs Grid */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-8 text-gray-900 dark:text-white">
            Available Labs
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {featuredLabs.map((lab) => (
              <div
                key={lab.id}
                onClick={() => handleLabClick(lab.id)}
                className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 cursor-pointer
                         transform transition-all duration-200 hover:scale-105"
              >
                <div className="flex items-center mb-4">
                  <lab.icon className="w-6 h-6 text-blue-600 dark:text-blue-400" />
                  <h3 className="ml-2 text-lg font-semibold text-gray-900 dark:text-white">
                    {lab.name}
                  </h3>
                </div>
                <p className="text-gray-600 dark:text-gray-300 mb-4">
                  {lab.description}
                </p>
                <div className="flex flex-wrap gap-2 mb-4">
                  {lab.tags.map((tag, index) => (
                    <span
                      key={index}
                      className="px-2 py-1 text-xs rounded-full bg-blue-100 text-blue-800
                               dark:bg-blue-900/20 dark:text-blue-300"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
                <div className="flex justify-between items-center">
                  <span className={`px-2 py-1 text-xs rounded-full 
                    ${lab.difficulty === 'Expert' 
                      ? 'bg-red-100 text-red-800 dark:bg-red-900/20 dark:text-red-300'
                      : lab.difficulty === 'Advanced'
                      ? 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/20 dark:text-yellow-300'
                      : 'bg-green-100 text-green-800 dark:bg-green-900/20 dark:text-green-300'
                    }`}>
                    {lab.difficulty}
                  </span>
                  <span className="text-blue-600 dark:text-blue-400">
                    Launch Lab →
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Statistics Section */}
      <section className="py-16 bg-gray-100 dark:bg-gray-800">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-6 text-center">
            <div>
              <h3 className="text-4xl font-bold text-blue-600">100+</h3>
              <p className="text-gray-600 dark:text-gray-300">Lab Environments</p>
            </div>
            <div>
              <h3 className="text-4xl font-bold text-blue-600">50+</h3>
              <p className="text-gray-600 dark:text-gray-300">CTF Challenges</p>
            </div>
            <div>
              <h3 className="text-4xl font-bold text-blue-600">10K+</h3>
              <p className="text-gray-600 dark:text-gray-300">Active Users</p>
            </div>
            <div>
              <h3 className="text-4xl font-bold text-blue-600">24/7</h3>
              <p className="text-gray-600 dark:text-gray-300">Lab Availability</p>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-900 text-white py-12">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div>
              <h3 className="text-xl font-bold mb-4">l1ackers.com</h3>
              <p className="text-gray-400">
                Your hub for ethical hacking and cybersecurity education.
                Practice responsibly in our secure lab environments.
              </p>
            </div>
            <div>
              <h4 className="font-bold mb-4">Quick Links</h4>
              <ul className="space-y-2">
                <li>
                  <button onClick={() => navigate('/labs')} className="text-gray-400 hover:text-white">
                    Labs
                  </button>
                </li>
                <li>
                  <button onClick={() => navigate('/tools')} className="text-gray-400 hover:text-white">
                    Tools
                  </button>
                </li>
                <li>
                  <button onClick={() => navigate('/challenges')} className="text-gray-400 hover:text-white">
                    Challenges
                  </button>
                </li>
                <li>
                  <button onClick={() => navigate('/community')} className="text-gray-400 hover:text-white">
                    Community
                  </button>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="font-bold mb-4">Resources</h4>
              <ul className="space-y-2">
                <li>
                  <button onClick={() => navigate('/documentation')} className="text-gray-400 hover:text-white">
                    Documentation
                  </button>
                </li>
                <li>
                  <button onClick={() => navigate('/api')} className="text-gray-400 hover:text-white">
                    API
                  </button>
                </li>
                <li>
                  <button onClick={() => navigate('/blog')} className="text-gray-400 hover:text-white">
                    Blog
                  </button>
                </li>
                <li>
                  <button onClick={() => navigate('/support')} className="text-gray-400 hover:text-white">
                    Support
                  </button>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="font-bold mb-4">Legal</h4>
              <ul className="space-y-2">
                <li>
                  <button onClick={() => navigate('/terms')} className="text-gray-400 hover:text-white">
                    Terms
                  </button>
                </li>
                <li>
                  <button onClick={() => navigate('/privacy')} className="text-gray-400 hover:text-white">
                    Privacy
                  </button>
                </li>
                <li>
                  <button onClick={() => navigate('/guidelines')} className="text-gray-400 hover:text-white">
                    Guidelines
                  </button>
                </li>
                <li>
                  <div className="flex items-center space-x-2 text-yellow-500">
                    <AlertTriangle className="w-4 h-4" />
                    <span className="text-sm">Educational Use Only</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="border-t border-gray-800 mt-8 pt-8 text-center text-gray-400">
            <p>© 2024 l1ackers.com. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;
